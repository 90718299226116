<template>
	<div>
		<TripHeader/>
		<div class="outer-wrapper mobile-only">
			<div class="travellers">

				<Loader :loading="loading || trip.loading" :error="trip.error"/>

				<div class="inner-wrapper" v-if="!loading && !trip.loading && !trip.error"> <!-- Inner wrapper handles global padding -->

					<h2 class="heading">Friend List</h2>

					<div class="friends">
						<!-- {{ travellers }} -->
						<div class="traveller-wrapper">
							<AddTraveller 
								text="Add new passenger"
								:tripID="trip_id"
							/> 

						</div>

						<div class="traveller-wrapper"
							v-for="traveller in friends"
							v-bind:key="traveller.traveller_id"
						>
							<TravellerSelector 
								:travellerID="traveller.traveller_id"
								:firstName="traveller.data.firstName"
								:lastName="traveller.data.lastName"
								:dob="traveller.data.dob"
								:passportNumber="traveller.data.passportNumber"
								:passportExpiry="traveller.data.passportExpiry"
								:nationality="traveller.data.countryOfIssue"

								:tripID="trip_id"

								:toggled="traveller.toggled"
								:loading="trip.loading"
							/> 
						</div>
					</div>

					<router-link :to="{ name: 'Trip', params: { trip_id: trip_id } }">
						<button class="button" :class="{ 'disabled': trip.loading }">Continue</button>
					</router-link>

				</div>
			</div>
		</div>
	</div>
</template>
<style scoped>
	a {
		text-decoration: none;
	}
	.outer-wrapper {
		padding-bottom: 60px;
	}
	.button {
		display: block;
		margin: 0 auto;
		margin-top: 23px;
	}
	
	/* pax */
	.friends .traveller-wrapper {
		text-align: center;
		margin-bottom: 15px;
	}
	img.icon {
		position: absolute;
		top: 21px;
		right: 40px;
	}
	/* end pax */

</style>
<script>
	import { mapState, mapActions } from 'vuex';
	import router from '@/router';
	import helpers from '@/helpers/helpers.js';
	import Menu from '@/components/Menu.vue'
	import Loader from '@/components/Loader.vue';
	import TravellerSelector from '@/components/travellers/TravellerSelector.vue';
	import AddTraveller from '@/components/travellers/AddTraveller.vue';
	import { travellerService } from '@/services/travellerService.js';
	import TripHeader from "@/components/trips/TripHeader.vue";

	export default {
		name: 'savedFlightDetails',
		props: ['flight'],
		data() {
			return {
				carbon: {
					img: "flights-details/carbon-forest-bg",	
				},
				score: {
					img: "flights-details/circle-score",
				},
				plane_img: {
					img: "trip/plane-image",
				},

				trip_id: this.$route.params.trip_id ? parseInt(this.$route.params.trip_id) : null,

				travellers: [],

				loading: false,

			}
		},
		components: {
			Menu,
			Loader,
			AddTraveller,
			TravellerSelector,
			TripHeader,
		},
		computed: {
			...mapState({
				trip: state => state.trip,
			}),
			fare() {
				return this.trip.tripItems[this.trip_item_id].fare;
			},
			data() {
				return this.tripItemData.data.flightDetails;
			},
			status() {
				return this.tripItemData.bookingStatus;
			},
			tripName() {
				return this.trip.tripName ? this.trip.tripName  : "Your Trip!!";
			},
		},
		methods: {
			...mapActions({
				initTrip: "trip/initTrip",
			}),
			formatPrice: (currency, to, howmany) => helpers.formatPrice(currency, to, howmany),
			getImage: (img) => helpers.getImage(img),
			formatDate: (date) => helpers.formatDate(date),
			calcTravellers() {
				this.travellers = [];
				this.friends = [];
				
				this.loading = true;
					
				let existingTravellerIds = [];

				this.trip.tripTravellers.forEach((traveller) => {
					existingTravellerIds.push(parseInt(traveller.traveller_id)); // Build array of Pax IDs that are already included (and so shouldn't be included in friends section)
					traveller.toggled = true;
					this.friends.push(traveller);
				});

				this.trip.userTravellers.forEach((traveller) => {
					traveller.toggled = false;
					if(!existingTravellerIds.includes(parseInt(traveller.traveller_id))) { // Add traveller option only if they are not on the trip item already
						this.friends.push(traveller);
					}
				});

				this.loading = false;
			}
		},
		async created() {
			this.$emit('update:topLayoutSettings', {
				left: {
					type: 'back',
					route: { name: 'Trip', params: { trip_id: this.trip_id } },
				},
				right: {
					type: 'none',
				},
				overlayContent: false,
			});

			await this.initTrip({ tripID: this.trip_id })

			// Update the color of the arrow now that the trip has been loaded and the header is shown
			this.$emit('update:topLayoutSettings', {
				left: {
					type: 'back',
					color: 'white',
					route: { name: 'Trip', params: { trip_id: this.trip_id } },
				},
				right: {
					type: 'none',
				},
				overlayContent: true,
			});
			this.calcTravellers();
		}
	}
</script>

